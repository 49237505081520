import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Checkbox, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemBackground, PageHeader, TableHeader } from '../../../app/AppStyles';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import ClassMemberAddCard from './ClassMemberAddCard';
import { deleteEmployeesFromClass, deleteStudentsFromClass, getSpecificClass, resetClassStatus, setFailedClassMembers } from './classManagementSlice';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';

export default function ClassMemberDisplay({ mode, members, classId }) {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedClassStatus, deleteStudentsStatus, deleteEmployeesStatus } = useSelector((state) => state.management.class);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selected, setSelected] = useState([]);
    const [openNew, setOpenNew] = useState(false);

    const newClick = () => setOpenNew(!openNew);

    const callbackModalNew = () => {
        setOpenNew(false);
        dispatch(setFailedClassMembers(false));
    };

    useEffect(() => {
        if ((mode === "STUDENT" && deleteStudentsStatus === "success") || (mode === "EMPLOYEE" && deleteEmployeesStatus === "success")) {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetClassStatus());
            dispatch(getSpecificClass(classId));
        } else if ((mode === "STUDENT" && deleteStudentsStatus === "failed") || (mode === "EMPLOYEE" && deleteEmployeesStatus === "failed")) {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetClassStatus());
        };
    }, [mode, classId, deleteStudentsStatus, deleteEmployeesStatus]);

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NUMBER}</TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    function EnhancedTableToolbar({ numSelected }) {
        const deleteClick = () => {
            var selectedMembers = [];
            var deleteObject = {};

            if (mode === "STUDENT") {
                selected.forEach(item => selectedMembers.push({
                    studentId: item.studentId,
                    name: item.name,
                    number: item.number
                }));

                deleteObject = {
                    classId: classId,
                    createdBy: `AP/${user.number}`,
                    students: selectedMembers
                };

                setSelected([]);
                dispatch(deleteStudentsFromClass(deleteObject));
            } else {
                selected.forEach(item => selectedMembers.push({
                    employeeId: item.employeeId,
                    name: item.name,
                    number: item.number
                }));

                deleteObject = {
                    classId: classId,
                    createdBy: `AP/${user.number}`,
                    employees: selectedMembers
                };

                setSelected([]);
                dispatch(deleteEmployeesFromClass(deleteObject));
            };
        };

        return (
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', ...(numSelected > 0 && { bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) }) }}>
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {resourceFinal[appLanguage].words.SELECTED.toLowerCase()}
                    </Typography>
                ) : (
                    <>
                        <Typography
                            sx={{ ...PageHeader }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            {mode === "STUDENT" ? resourceFinal[appLanguage].words.STUDENTS : resourceFinal[appLanguage].words.EMPLOYEES}
                        </Typography>
                        <Button variant="outlined" onClick={() => newClick("Head")} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                    </>
                )}

                {numSelected > 0 &&
                    <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />}
                    message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${numSelected} ${numSelected > 1 ? (mode === "STUDENT" ? resourceFinal[appLanguage].words.STUDENTS.toLowerCase() : resourceFinal[appLanguage].words.EMPLOYEES.toLowerCase()) : (mode === "STUDENT" ? resourceFinal[appLanguage].words.STUDENT.toLowerCase() : resourceFinal[appLanguage].words.ROLE_EMPLOYEE.toLowerCase())} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`}
                        confirmAction={() => deleteClick(selected)}
                    />}
            </Toolbar>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = members.map((n) => n);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        };

        setSelected(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (member) => {
        if (mode === "STUDENT") {
            return selected.findIndex(x => x.studentId === member.studentId) !== -1;
        } else {
            return selected.findIndex(x => x.employeeId === member.employeeId) !== -1;
        };
    };

    return (
        <ContentLoader successCondition={selectedClassStatus === "success"} errorCondition={selectedClassStatus === "failed"} content={
            <>
                <Box sx={{ p: 0.5, ...ListItemBackground }}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer>
                        <Table>
                            <EnhancedTableHead numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={members?.length} />
                            <TableBody>
                                {(rowsPerPage > 0 ? members?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : members)?.map((item, index) => {
                                    const isItemSelected = isSelected(item);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow hover onClick={(event) => handleClick(event, item)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item?.number} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                            <TableCell padding="checkbox">
                                                <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                            </TableCell>
                                            <TableCell align="left" id={labelId}>{item?.number}</TableCell>
                                            <TableCell align="left">{item?.name}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {(rowsPerPage > 0 ? members?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : members)?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                                {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        sx={{ border: 0 }}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        count={members ? members.length : 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                        labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
                <Modal open={openNew} onClose={callbackModalNew}>
                    <>
                        <ClassMemberAddCard handleClose={callbackModalNew} classId={classId} mode={mode} />
                    </>
                </Modal>
            </>
        } />
    );
}

