import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const getPeriods = createAsyncThunk(
    '/api/periodManagement/periods',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/periodManagement`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putPeriod = createAsyncThunk(
    "/api/periodManagement/put",
    async (updatedPeriod, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/periodManagement",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(updatedPeriod),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postPeriod = createAsyncThunk(
    "/api/periodManagement/post",
    async (newPeriod, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/periodManagement",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify(newPeriod),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deletePeriod = createAsyncThunk(
    "/api/periodManagement/delete",
    async (periodId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/periodManagement/${periodId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "DELETE",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const periodManagementSlice = createSlice({
    name: 'periodManagement',
    initialState: {
        periods: null,
        periodsStatus: null,
        putPeriodStatus: null,
        postPeriodStatus: null,
        deletePeriodStatus: null,
        selectedPeriod: null,
        showPeriodFailed: false,
    },
    reducers: {
        selectPeriod: (state, action) => {
            state.selectedPeriod = action.payload;
        },
        setFailedPeriod: (state, action) => {
            state.showPeriodFailed = action.payload;
        },
        resetPeriodStatus: (state) => {
            state.putPeriodStatus = null;
            state.postPeriodStatus = null;
            state.deletePeriodStatus = null;
        },
    },
    extraReducers: (builder) => {
        builder // GET
            .addCase(getPeriods.pending, (state) => {
                state.periodsStatus = 'loading';
            })
            .addCase(getPeriods.fulfilled, (state, action) => {
                state.periodsStatus = 'success';
                state.periods = action.payload;
            })
            .addCase(getPeriods.rejected, (state) => {
                state.periodsStatus = 'failed';
            });

        builder // PUT
            .addCase(putPeriod.pending, (state) => {
                state.putPeriodStatus = 'loading';
            })
            .addCase(putPeriod.fulfilled, (state) => {
                state.putPeriodStatus = 'success';
            })
            .addCase(putPeriod.rejected, (state) => {
                state.putPeriodStatus = 'failed';
            });

        builder // POST
            .addCase(postPeriod.pending, (state) => {
                state.postPeriodStatus = 'loading';
            })
            .addCase(postPeriod.fulfilled, (state) => {
                state.postPeriodStatus = 'success';
            })
            .addCase(postPeriod.rejected, (state) => {
                state.postPeriodStatus = 'success';
            });

        builder // DELETE
            .addCase(deletePeriod.pending, (state) => {
                state.deletePeriodStatus = 'loading';
            })
            .addCase(deletePeriod.fulfilled, (state) => {
                state.deletePeriodStatus = 'success';
            })
            .addCase(deletePeriod.rejected, (state) => {
                state.deletePeriodStatus = 'failed';
            });
    },
});

export const {
    selectPeriod,
    setFailedPeriod,
    resetPeriodStatus
} = periodManagementSlice.actions;

export default periodManagementSlice.reducer;
