import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const getAllProgramLinks = createAsyncThunk(
    '/api/LinkProgram/links',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/LinkProgram/links`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const getProgramsToLink = createAsyncThunk(
    '/api/LinkProgram/programs',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/LinkProgram/programs`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const getDepartmentsToLink = createAsyncThunk(
    '/api/LinkProgram/departments',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/LinkProgram/departments`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const getItemsToLink = createAsyncThunk(
    '/api/LinkProgram/items/mode',
    async (mode, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/LinkProgram/items/${mode}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postProgramLinks = createAsyncThunk(
    "/api/LinkProgram/post",
    async (newProgramLinks, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/LinkProgram",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify(newProgramLinks),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putProgramLink = createAsyncThunk(
    "/api/periodManagement/put",
    async (programLink, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/LinkProgram",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(programLink),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const linkProgramSlice = createSlice({
    name: 'linkProgram',
    initialState: {
        programLinks: null,
        programLinksStatus: null,
        programsToLink: null,
        programsToLinkStatus: null,
        departmentsToLink: null,
        departmentsToLinkStatus: null,
        itemsToLink: null,
        itemsToLinkStatus: null,
        postProgramLinkStatus: null,
        putProgramLinkStatus: null,
        showProgramLinkFailed: false
    },
    reducers: {
        setFailedProgramLink: (state, action) => {
            state.showProgramLinkFailed = action.payload;
        },
        resetProgramLinkStatus: (state) => {
            state.putProgramLinkStatus = null;
            state.postProgramLinkStatus = null;
        },
        resetItemsToLink: (state) => {
            state.itemsToLink = null;
            state.itemsToLinkStatus = null;
        },
    },
    extraReducers: (builder) => {
        builder // GET PROGRAM LINKS
            .addCase(getAllProgramLinks.pending, (state) => {
                state.programLinksStatus = 'loading';
            })
            .addCase(getAllProgramLinks.fulfilled, (state, action) => {
                state.programLinksStatus = 'success';
                state.programLinks = action.payload;
            })
            .addCase(getAllProgramLinks.rejected, (state) => {
                state.programLinksStatus = 'failed';
            });

        builder // GET PROGRAMS
            .addCase(getProgramsToLink.pending, (state) => {
                state.programsToLinkStatus = 'loading';
            })
            .addCase(getProgramsToLink.fulfilled, (state, action) => {
                state.programsToLinkStatus = 'success';
                state.programsToLink = action.payload;
            })
            .addCase(getProgramsToLink.rejected, (state) => {
                state.programsToLinkStatus = 'failed';
            });

        builder // GET DEPARTMENTS
            .addCase(getDepartmentsToLink.pending, (state) => {
                state.departmentsToLinkStatus = 'loading';
            })
            .addCase(getDepartmentsToLink.fulfilled, (state, action) => {
                state.departmentsToLinkStatus = 'success';
                state.departmentsToLink = action.payload;
            })
            .addCase(getDepartmentsToLink.rejected, (state) => {
                state.departmentsToLinkStatus = 'failed';
            });

        builder // GET ITEMS TO LINK
            .addCase(getItemsToLink.pending, (state) => {
                state.itemsToLinkStatus = 'loading';
            })
            .addCase(getItemsToLink.fulfilled, (state, action) => {
                state.itemsToLinkStatus = 'success';
                state.itemsToLink = action.payload;
            })
            .addCase(getItemsToLink.rejected, (state) => {
                state.itemsToLinkStatus = 'failed';
            });


        builder // POST
            .addCase(postProgramLinks.pending, (state) => {
                state.postProgramLinkStatus = 'loading';
            })
            .addCase(postProgramLinks.fulfilled, (state) => {
                state.postProgramLinkStatus = 'success';
            })
            .addCase(postProgramLinks.rejected, (state) => {
                state.postProgramLinkStatus = 'success';
            });

        builder // PUT
            .addCase(putProgramLink.pending, (state) => {
                state.putProgramLinkStatus = 'loading';
            })
            .addCase(putProgramLink.fulfilled, (state) => {
                state.putProgramLinkStatus = 'success';
            })
            .addCase(putProgramLink.rejected, (state) => {
                state.putProgramLinkStatus = 'failed';
            });
    },
});

export const {
    setFailedProgramLink,
    resetProgramLinkStatus,
    resetItemsToLink
} = linkProgramSlice.actions;

export default linkProgramSlice.reducer;
