import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Pagination, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CardBgcolor, PageHeader, TableHeader } from '../../../app/AppStyles';
import { GetDate, GetDateLong, GetMonthName, GetTime, GetYear } from '../../../app/AppUtils';
import { getAllContacts } from '../../contact/contactSlice';
import { getLocations } from '../../management-activity/location/locationManagementSlice';
import IconDisplay from '../../subcomponents/IconDisplay';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import PlanningPerformanceEditCard from '../performances/PlanningPerformanceEditCard';
import { resetDefaultActivityValues, resetPlanningPerformanceStatus, selectPerformance, setFailedPerformance } from '../performances/planningPerformanceSlice';
import { fetchDayPlanning, fetchMonthPlanning, fetchPeriodPlanning, fetchWeekPlanning, fetchYearPlanning, setAccordionSelectedDate } from '../planningSlice';

export default function PlanningDateAccordion({ title, amount, type }) {
    const dispatch = useDispatch();

    const {
        dayPlanning,
        dayPlanningStatus,
        weekPlanning,
        weekPlanningStatus,
        monthPlanning,
        monthPlanningStatus,
        yearPlanning,
        yearPlanningStatus,
        periodPlanning,
        periodPlanningStatus,
        accordionSelectedDate,
        planningPeriods
    } = useSelector((state) => state.planning.planning);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { appSettings } = useSelector((state) => state.settings.app);

    const [itemsLeft, setItemsLeft] = useState(0);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [openNew, setOpenNew] = useState(false);
    const [content, setContent] = useState();
    const [status, setStatus] = useState();

    useEffect(() => {
        if (type === "day") {
            dispatch(setAccordionSelectedDate(dayjs(dayPlanning.date).toString()));
            setContent(dayPlanning);
            setStatus(dayPlanningStatus);
        };

        if (type === "week") {
            dispatch(setAccordionSelectedDate(dayjs(weekPlanning.weekStart).toString()));
            setContent(weekPlanning);
            setStatus(weekPlanningStatus);
        };

        if (type === "month") {
            dispatch(setAccordionSelectedDate(dayjs(monthPlanning.monthStart).toString()));
            setContent(monthPlanning);
            setStatus(monthPlanningStatus);
        };

        if (type === "year") {
            dispatch(setAccordionSelectedDate(dayjs(yearPlanning.yearStart).toString()));
            setContent(yearPlanning);
            setStatus(yearPlanningStatus);
        };

        if (type === "period") {
            dispatch(setAccordionSelectedDate(dayjs(periodPlanning.period.startTime).toString()));
            setContent(periodPlanning);
            setStatus(periodPlanningStatus);
        };
    }, [type, dayPlanning, weekPlanning, monthPlanning, yearPlanning, periodPlanning])

    useEffect(() => {
        if (status === "success") {
            const size = content?.groupedActivities?.length;
            setItemsLeft(size - amount);
        };
    }, [status, amount, content]);

    useEffect(() => {
        setPages(Math.ceil(itemsLeft / amount) + 1);
    }, [itemsLeft, amount]);

    useEffect(() => {
        dispatch(getLocations());
        dispatch(getAllContacts());
    }, [dispatch]);

    const handleChange = (event, value) => setPage(value);

    const newClick = (item) => {
        dispatch(selectPerformance({ activity: { id: item.id, name: item.name } }));
        setOpenNew(!openNew);
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        dispatch(resetPlanningPerformanceStatus());
        dispatch(resetDefaultActivityValues());
        dispatch(setFailedPerformance(false));
    };

    const handleDateButtonChange = (up) => {
        if (type === "day") {
            var currentDay = new Date(content?.date);
            var newDay = new Date(currentDay.setDate(up ? currentDay.getDate() + 1 : currentDay.getDate() - 1));
            dispatch(setAccordionSelectedDate(dayjs(newDay).toString()));
            dispatch(fetchDayPlanning(newDay.toISOString()));
        };

        if (type === "week") {
            var currentWeek = new Date(content?.weekStart);
            var newWeek = new Date(currentWeek.setDate(up ? currentWeek.getDate() + 7 : currentWeek.getDate() - 7));
            dispatch(setAccordionSelectedDate(dayjs(newWeek).toString()));
            dispatch(fetchWeekPlanning(newWeek.toISOString()));
        };

        if (type === "month") {
            var currentMonth = new Date(content?.monthStart);
            var newMonth = new Date(currentMonth.setMonth(up ? currentMonth.getMonth() + 2 : currentMonth.getMonth()));
            dispatch(setAccordionSelectedDate(dayjs(newMonth).toString()));
            dispatch(fetchMonthPlanning(newMonth.toISOString()));
        };

        if (type === "year") {
            var currentYear = new Date(content?.yearStart);
            var newYear = new Date(currentYear.setFullYear(up ? currentYear.getFullYear() + 2 : currentYear.getFullYear()));
            dispatch(setAccordionSelectedDate(dayjs(newYear).toString()));
            dispatch(fetchYearPlanning(newYear.toISOString()));
        };
    };

    const handleDatePickerChange = (e) => {
        dispatch(setAccordionSelectedDate(dayjs(e).toString()));
        var newDate = new Date(e);
        if (type === "day")
            dispatch(fetchDayPlanning(newDate.toISOString()));

        if (type === "week")
            dispatch(fetchWeekPlanning(newDate.toISOString()));

        if (type === "month")
            dispatch(fetchMonthPlanning(new Date(newDate.setMonth(newDate.getMonth() + 1)).toISOString()));

        if (type === "year")
            dispatch(fetchYearPlanning(newDate.toISOString()));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
            <Stack spacing={1} direction="row" justifyContent="center">
                {status === "success" && <Typography variant="h6" sx={{ ...PageHeader }}>{title} {resourceFinal[appLanguage].words.DISPLAY.toLowerCase()}:</Typography>}
                {(type === "day" && status === "success") && <Typography variant="h6" sx={{ ...PageHeader }}>{GetDateLong(content?.date)} ({content?.groupedActivities?.length})</Typography>}
                {(type === "week" && status === "success") && <Typography variant="h6" sx={{ ...PageHeader }}>{GetDate(content?.weekStart)} - {GetDate(content?.weekEnd)} ({content?.groupedActivities?.length})</Typography>}
                {(type === "month" && status === "success") && <Typography variant="h6" sx={{ ...PageHeader }}>{GetMonthName(content?.monthStart)} {GetYear(content?.monthStart)} ({content?.groupedActivities?.length})</Typography>}
                {(type === "year" && status === "success") && <Typography variant="h6" sx={{ ...PageHeader }}>{GetYear(content?.yearStart)} - {GetYear(dayjs(content?.yearStart).add(1, 'year'))} ({content?.groupedActivities?.length})</Typography>}
                {(type === "period" && status === "success") && <Typography variant="h6" sx={{ ...PageHeader }}>{content?.period.name} ({content?.groupedActivities?.length})</Typography>}
            </Stack>
            <Box sx={{ mb: 0.5 }}>
                {type !== "period" ?
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ mb: 1 }}>
                        <Button variant="outlined" color="secondary" sx={{ width: 100, height: 56 }} onClick={() => handleDateButtonChange(false)}>{resourceFinal[appLanguage].words.PREVIOUS}</Button>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                            <DatePicker sx={{ mr: 1 }}
                                label={resourceFinal[appLanguage].words.DATE}
                                views={type === "day" || type === "week" ? ['year', 'month', 'day'] : type === "month" ? ['year', 'month'] : ['year']}
                                value={dayjs(accordionSelectedDate)}
                                onChange={(e) => handleDatePickerChange(e)} />
                        </LocalizationProvider>
                        <Button variant="outlined" color="secondary" sx={{ width: 100, height: 56 }} onClick={() => handleDateButtonChange(true)}>{resourceFinal[appLanguage].words.NEXT}</Button>
                    </Stack>
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, width: 1 }}>
                        <FormControl sx={{ width: '40%' }}>
                            <InputLabel>{resourceFinal[appLanguage].words.PERIOD}</InputLabel>
                            <Select label={resourceFinal[appLanguage].words.PERIOD} defaultValue={''}
                                onChange={(event) => {
                                    dispatch(fetchPeriodPlanning(event.target.value));
                                }}>
                                {planningPeriods?.map((item) => <MenuItem key={item.periodId} value={item.periodId}>{item.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>}
                {content?.groupedActivities?.slice((page - 1) * amount, page * amount)?.map((item) =>
                    <Accordion key={item.id} sx={{ bgcolor: CardBgcolor }}>
                        <AccordionSummary sx={{ display: 'flex' }}>
                            <Avatar sx={{ mr: 1, bgcolor: 'secondary.main', color: 'white.light' }}>
                                <IconDisplay component={item.performances[0].activity.iconName} />
                            </Avatar>
                            <Typography sx={{ flexGrow: 1, alignSelf: 'center' }}>
                                {item.name}
                            </Typography>
                            <Typography sx={{ alignSelf: 'center' }}>({item.performances.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="outlined" onClick={() => newClick(item)} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                            </Box>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {appSettings?.appVersion === 2 && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>}
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NUMBER}</TableCell>
                                            {appSettings?.appVersion === 1 && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.MOMENTS}</TableCell>}
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.TIMEFRAME}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.CAPACITY}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.SUBSCRIBABLE_FROM}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.SUBSCRIBABLE_UNTIL}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <PerformancesFragment subcontent={item.performances} />
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                )}
            </Box>
            {content?.groupedActivities?.length === 0 && <Typography variant="h6" sx={{ width: 1, textAlign: 'center', my: 'auto' }}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</Typography>}
            {pages > 1 && <Pagination sx={{ mt: 'auto', alignSelf: 'center' }} count={pages} page={page} onChange={handleChange} defaultPage={1} color="secondary" />}

            <Modal open={openNew} onClose={callbackModalNew}>
                <>
                    <PlanningPerformanceEditCard handleClose={callbackModalNew} addNew waitForInput />
                </>
            </Modal>
        </Box >
    );
}

const PerformancesFragment = ({ subcontent }) => {
    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { appSettings } = useSelector((state) => state.settings.app);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subcontent.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const performanceClick = (performanceNumber, performanceId) => {
        navigate(`/plannen/uitvoering/${performanceNumber}/${performanceId}`)
    };

    return (
        <>
            <TableBody>
                {(rowsPerPage > 0 ? subcontent?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : subcontent)?.map(item =>
                    <TableRow key={item.id} hover onClick={() => performanceClick(item.number, item.id)} sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}>
                        {appSettings?.appVersion === 2 && <TableCell align="center">{item.name}</TableCell>}
                        <TableCell align="center">{item.number}</TableCell>
                        {appSettings?.appVersion === 1 && <TableCell align="center">{item.moments}</TableCell>}
                        <TableCell align="center">
                            {item.moments > 1 ?
                                `${GetDate(item.activityMoments[0].starttime)} - ${GetDate(item.activityMoments[item.activityMoments.length - 1].starttime)} 
                                        (${GetTime(item.activityMoments[0].starttime)} - ${GetTime(item.activityMoments[0].endtime)})` :
                                `${GetDate(item.activityMoments[0].starttime)} (${GetTime(item.activityMoments[0].starttime)} - ${GetTime(item.activityMoments[0].endtime)})`
                            }
                        </TableCell>
                        <TableCell align="center">{item.capacityCurrent}/{item.capacityLimit}</TableCell>
                        <TableCell align="center">{GetDate(item.registerableFrom)} {GetTime(item.registerableFrom)}</TableCell>
                        <TableCell align="center">{GetDate(item.registerableUntil)} {GetTime(item.registerableUntil)}</TableCell>
                        <TableCell align="center" padding="checkbox"><ArrowForwardIosIcon color="secondary" /></TableCell>
                    </TableRow>
                )}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>)}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        count={subcontent?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                        labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                    />
                </TableRow>
            </TableFooter>
        </>
    );
};
