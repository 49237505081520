import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip, alpha } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { MainBorderRadius, TableHeader } from '../../../app/AppStyles';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import ClassEditCard from './ClassEditCard';
import { getClasses, resetClassStatus, resetSelectedClass, setFailedClass } from './classManagementSlice';

export default function ClassTab() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const textRef = useRef(null);

    const [openNew, setOpenNew] = useState(false);
    const [filter, setFilter] = useState("");

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { classes, classesStatus } = useSelector((state) => state.management.class);

    useEffect(() => {
        dispatch(getClasses(true));
    }, [dispatch]);

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    var customFilterFunction = (item) => {
        return item.name.toLowerCase().includes(filter.toLowerCase());
    };

    const newClick = () => {
        dispatch(setFailedClass(false));
        dispatch(resetSelectedClass());
        setOpenNew(true);
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        setFilter("");
        dispatch(resetClassStatus());
        dispatch(setFailedClass(false));
    };

    const licensePageClick = () => {
        navigate(`/programmabeheer/klas/activeren`);
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - classes?.filter(customFilterFunction).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const selectClick = (classId) => {
            navigate(`/programmabeheer/klas/${classId}`);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? classes.filter(customFilterFunction).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : classes.filter(customFilterFunction)).map(item =>
                        <TableRow key={item.classId} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">
                                <Link to={`/programmabeheer/klas/${item.classId}`} style={{ color: 'black' }}>
                                    {item.name}
                                </Link>
                            </TableCell>
                            <Tooltip title={item.description}>
                                <TableCell align="left" sx={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "200px",
                                    width: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{item.description ? item.description : "-"}</TableCell>
                            </Tooltip>
                            <TableCell align="center" padding="checkbox">{item.isLicensed ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}</TableCell>
                            <TableCell align="center" padding="checkbox">{item.students.length}</TableCell>
                            <TableCell align="center" padding="checkbox">{item.employees.length}</TableCell>
                            <TableCell align="center" padding="checkbox">
                                <IconButton onClick={() => selectClick(item.classId)}><ArrowForwardIosIcon color="secondary" /></IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? classes.filter(customFilterFunction).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : classes.filter(customFilterFunction)).length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={13} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 20, { label: resourceFinal[appLanguage].words.ALL, value: -1 }]}
                            count={classes?.filter(customFilterFunction).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <>
            <ContentLoader successCondition={classesStatus === "success"} errorCondition={classesStatus === "failed"} content={
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: theme => alpha(theme.palette.primary.light, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                        <SearchField func={handleSearchChange} textRef={textRef} />
                        <Box>
                            <Button sx={{ mr: 1 }} variant="outlined" onClick={licensePageClick} startIcon={<CheckIcon />}>{resourceFinal[appLanguage].words.LICENSE}</Button>
                            <Button variant="outlined" onClick={newClick} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                        </Box>
                    </Box>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DESCRIPTION}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LICENSED}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.STUDENTS}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.EMPLOYEES}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                </Box >
            } />

            < Modal open={openNew} onClose={callbackModalNew} >
                <>
                    <ClassEditCard handleClose={callbackModalNew} addNew />
                </>
            </Modal >
        </>
    );
}
