import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchAllAttendances = createAsyncThunk(
    "/api/presence",
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/presence`, {
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: !token ? {} : `Bearer ${token}`,
            }),
            method: "GET",
            redirect: "follow",
        });

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

export const fetchMomentAttendance = createAsyncThunk(
    "/api/presence/moment/momentId",
    async (momentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/presence/moment/${momentId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

export const fetchPerformanceAttendance = createAsyncThunk(
    "/api/presence/performance/performanceId",
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/presence/performance/${performanceId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

export const postAttendances = createAsyncThunk(
    "/api/presence/post",
    async (newAttendances, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/presence`, {
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: !token ? {} : `Bearer ${token}`,
            }),
            method: "POST",
            redirect: "follow",
            body: JSON.stringify(newAttendances),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const attendanceSlice = createSlice({
    name: "attendance",
    initialState: {
        attendances: null,
        attendancesStatus: null,
        attendancesPerformance: null,
        attendancesPerformanceStatus: null,
        attendanceMoment: null,
        attendanceMomentStatus: null,
        postAttendanceStatus: null,
    },
    reducers: {
        setAllPresents: (state, action) => {
            state.attendanceMoment.presents.forEach((item) => (item.present = action.payload));
        },
        setPresent: (state, action) => {
            const index = state.attendanceMoment.presents.findIndex(
                (item) => item.studentId === action.payload.id
            );
            state.attendanceMoment.presents[index].present = action.payload.value;
        },
        setDispensation: (state, action) => {
            const index = state.attendanceMoment.presents.findIndex(
                (item) => item.studentId === action.payload.id
            );
            state.attendanceMoment.presents[index].dispensation = action.payload.value;
        },
        setWorkedHours: (state, action) => {
            const index = state.attendanceMoment.presents.findIndex(
                (item) => item.studentId === action.payload.id
            );
            state.attendanceMoment.presents[index].workedHours = action.payload.value;
        },
        setRemark: (state, action) => {
            const index = state.attendanceMoment.presents.findIndex(
                (item) => item.studentId === action.payload.id
            );
            state.attendanceMoment.presents[index].remark = action.payload.value;
        },
        setAllCreatedBy: (state, action) => {
            state.attendanceMoment.presents.forEach((item) => (item.createdBy = action.payload));
        },
        setAllModifiedBy: (state, action) => {
            state.attendanceMoment.presents.forEach((item) => (item.modifiedBy = action.payload));
        },
        resetAttendanceStatus: (state) => {
            state.attendancesStatus = null;
            state.attendanceMomentStatus = null;
            state.postAttendanceStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Get all attendances
        builder
            .addCase(fetchAllAttendances.pending, (state) => {
                state.attendancesStatus = "loading";
            })
            .addCase(fetchAllAttendances.fulfilled, (state, action) => {
                state.attendancesStatus = "success";
                state.attendances = action.payload;
            })
            .addCase(fetchAllAttendances.rejected, (state) => {
                state.attendancesStatus = "failed";
            });

        // Get moment attendance
        builder
            .addCase(fetchMomentAttendance.pending, (state) => {
                state.attendanceMomentStatus = "loading";
            })
            .addCase(fetchMomentAttendance.fulfilled, (state, action) => {
                state.attendanceMomentStatus = "success";
                state.attendanceMoment = action.payload;
            })
            .addCase(fetchMomentAttendance.rejected, (state) => {
                state.attendanceMomentStatus = "failed";
            });

        // Get performance attendances
        builder
            .addCase(fetchPerformanceAttendance.pending, (state) => {
                state.attendancesPerformanceStatus = "loading";
            })
            .addCase(fetchPerformanceAttendance.fulfilled, (state, action) => {
                state.attendancesPerformanceStatus = "success";
                state.attendancesPerformance = action.payload;
            })
            .addCase(fetchPerformanceAttendance.rejected, (state) => {
                state.attendancesPerformanceStatus = "failed";
            });

        // Post attendances
        builder
            .addCase(postAttendances.pending, (state) => {
                state.postAttendanceStatus = "loading";
            })
            .addCase(postAttendances.fulfilled, (state) => {
                state.postAttendanceStatus = "success";
            })
            .addCase(postAttendances.rejected, (state) => {
                state.postAttendanceStatus = "failed";
            });
    },
});

export const {
    setAllPresents,
    setPresent,
    setDispensation,
    setWorkedHours,
    setRemark,
    setAllCreatedBy,
    setAllModifiedBy,
    resetAttendanceStatus,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
