import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchPlanningMoments = createAsyncThunk(
    '/api/planningMoments/id',
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planningMoments/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const planningMomentsSlice = createSlice({
    name: 'planningMoments',
    initialState: {
        planningMoments: null,
        planningMomentsStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Get planning moments
        builder
            .addCase(fetchPlanningMoments.pending, (state) => {
                state.planningMomentsStatus = 'loading';
            })
            .addCase(fetchPlanningMoments.fulfilled, (state, action) => {
                state.planningMoments = action.payload;
                state.planningMomentsStatus = 'success';
            })
            .addCase(fetchPlanningMoments.rejected, (state) => {
                state.planningMomentsStatus = 'failed';
            });
    }
});

//export const { } = planningMomentsSlice.actions;

export default planningMomentsSlice.reducer;