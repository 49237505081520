import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../subcomponents/BackButton';
import ContentLoader from '../../subcomponents/ContentLoader';
import PageLayout from '../../subcomponents/PageLayout';
import LicenseClassesDisplay from './LicenseClassesDisplay';
import { getClassesForLicensing } from './classManagementSlice';

export default function LicenseClassesPage() {
    const dispatch = useDispatch();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { licensingClasses, licensingClassesStatus } = useSelector((state) => state.management.class);

    const [licensedClasses, setLicensedClasses] = useState([]);
    const [unlicensedClasses, setUnlicensedClasses] = useState([]);

    useEffect(() => {
        dispatch(getClassesForLicensing());
    }, []);

    useEffect(() => {
        if (licensingClassesStatus === "success") {
            setLicensedClasses(licensingClasses?.filter((item) => item.isLicensed === true));
            setUnlicensedClasses(licensingClasses?.filter((item) => item.isLicensed === false));
        };
    }, [licensingClassesStatus]);

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.LICENSE}`} content={
            <ContentLoader successCondition={licensingClassesStatus === "success"} errorCondition={licensingClassesStatus === "failed"} content={
                <>
                    <Grid container spacing={1} sx={{ mt: { xs: 0, md: 1 } }}>
                        <Grid item xs={12}>
                            <LicenseClassesDisplay classes={licensedClasses} mode="REMOVE" />
                        </Grid>
                        <Grid item xs={12}>
                            <LicenseClassesDisplay classes={unlicensedClasses} mode="ADD" />
                        </Grid>
                    </Grid>
                </>
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } bottomBar />
    );
}
