import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const getClasses = createAsyncThunk(
    '/api/classManagement/isLicensed',
    async (isLicensed, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/classManagement/${isLicensed}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const getSpecificClass = createAsyncThunk(
    '/api/classManagement/schoolClass/classId',
    async (classId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/classManagement/schoolClass/${classId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const getPotentialStudents = createAsyncThunk(
    '/api/classManagement/students/classId',
    async (classId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/classManagement/students/${classId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const getPotentialEmployees = createAsyncThunk(
    '/api/classManagement/employees/classId',
    async (classId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/classManagement/employees/${classId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const getClassesForLicensing = createAsyncThunk(
    '/api/classManagement/licensing',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/classManagement/licensing`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postClass = createAsyncThunk(
    "/api/classManagement/addClass",
    async (newClass, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/classManagement/addClass",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify(newClass),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putClass = createAsyncThunk(
    "/api/classManagement/put",
    async (updatedClass, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/classManagement",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(updatedClass),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postStudentsToClass = createAsyncThunk(
    "/api/classManagement/addStudents",
    async (newStudents, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/classManagement/addStudents",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify(newStudents),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deleteStudentsFromClass = createAsyncThunk(
    "/api/classManagement/removeStudents",
    async (oldStudents, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/classManagement/removeStudents`,
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "DELETE",
                redirect: "follow",
                body: JSON.stringify(oldStudents),
            }
        );
        console.log(response)
        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postEmployeesToClass = createAsyncThunk(
    "/api/classManagement/addEmployees",
    async (newEmployees, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/classManagement/addEmployees",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify(newEmployees),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deleteEmployeesFromClass = createAsyncThunk(
    "/api/classManagement/removeEmployees",
    async (oldEmployees, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/classManagement/removeEmployees`,
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "DELETE",
                redirect: "follow",
                body: JSON.stringify(oldEmployees),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const changeLicenseClass = createAsyncThunk(
    "/api/classManagement/changeLicense",
    async (lisenceChangeObject, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/classManagement/changeLicense`,
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(lisenceChangeObject),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const classManagementSlice = createSlice({
    name: 'classManagement',
    initialState: {
        classes: null,
        classesStatus: null,
        selectedClass: null,
        selectedClassStatus: null,
        potentialStudents: null,
        potentialStudentsStatus: null,
        potentialEmployees: null,
        potentialEmployeesStatus: null,
        licensingClasses: null,
        licensingClassesStatus: null,
        postClassStatus: null,
        putClassStatus: null,
        postStudentsStatus: null,
        deleteStudentsStatus: null,
        postEmployeesStatus: null,
        deleteEmployeesStatus: null,
        changeLicenseClassStatus: null,
        showClassFailed: false,
        showClassMembersFailed: false,
        showClassLicenseFailed: false,
    },
    reducers: {
        setFailedClass: (state, action) => {
            state.showClassFailed = action.payload;
        },
        setFailedClassMembers: (state, action) => {
            state.showClassMembersFailed = action.payload;
        },
        setFailedClassLicense: (state, action) => {
            state.showClassMembersFailed = action.payload;
        },
        resetSelectedClass: (state) => {
            state.selectedClass = null;
            state.selectedClassStatus = null;
        },
        resetClassStatus: (state) => {
            state.postClassStatus = null;
            state.putClassStatus = null;
            state.postStudentsStatus = null;
            state.deleteStudentsStatus = null;
            state.postEmployeesStatus = null;
            state.deleteEmployeesStatus = null;
            state.changeLicenseClassStatus = null;
        },
    },
    extraReducers: (builder) => {
        builder // GET CLASSES
            .addCase(getClasses.pending, (state) => {
                state.classesStatus = 'loading';
            })
            .addCase(getClasses.fulfilled, (state, action) => {
                state.classesStatus = 'success';
                state.classes = action.payload;
            })
            .addCase(getClasses.rejected, (state) => {
                state.classesStatus = 'failed';
            });

        builder // GET SPECIFIC CLASS
            .addCase(getSpecificClass.pending, (state) => {
                state.selectedClassStatus = 'loading';
            })
            .addCase(getSpecificClass.fulfilled, (state, action) => {
                state.selectedClassStatus = 'success';
                state.selectedClass = action.payload;
            })
            .addCase(getSpecificClass.rejected, (state) => {
                state.selectedClassStatus = 'failed';
            });

        builder // GET STUDENTS
            .addCase(getPotentialStudents.pending, (state) => {
                state.potentialStudentsStatus = 'loading';
            })
            .addCase(getPotentialStudents.fulfilled, (state, action) => {
                state.potentialStudentsStatus = 'success';
                state.potentialStudents = action.payload;
            })
            .addCase(getPotentialStudents.rejected, (state) => {
                state.potentialStudentsStatus = 'failed';
            });

        builder // GET EMPLOYEES
            .addCase(getPotentialEmployees.pending, (state) => {
                state.potentialEmployeesStatus = 'loading';
            })
            .addCase(getPotentialEmployees.fulfilled, (state, action) => {
                state.potentialEmployeesStatus = 'success';
                state.potentialEmployees = action.payload;
            })
            .addCase(getPotentialEmployees.rejected, (state) => {
                state.potentialEmployeesStatus = 'failed';
            });

        builder // GET LICENSING CLASSES
            .addCase(getClassesForLicensing.pending, (state) => {
                state.licensingClassesStatus = 'loading';
            })
            .addCase(getClassesForLicensing.fulfilled, (state, action) => {
                state.licensingClassesStatus = 'success';
                state.licensingClasses = action.payload;
            })
            .addCase(getClassesForLicensing.rejected, (state) => {
                state.licensingClassesStatus = 'failed';
            });

        builder // POST CLASS
            .addCase(postClass.pending, (state) => {
                state.postClassStatus = 'loading';
            })
            .addCase(postClass.fulfilled, (state) => {
                state.postClassStatus = 'success';
            })
            .addCase(postClass.rejected, (state) => {
                state.postClassStatus = 'failed';
            });

        builder // PUT CLASS
            .addCase(putClass.pending, (state) => {
                state.putClassStatus = 'loading';
            })
            .addCase(putClass.fulfilled, (state) => {
                state.putClassStatus = 'success';
            })
            .addCase(putClass.rejected, (state) => {
                state.putClassStatus = 'success';
            });

        builder // POST STUDENTS
            .addCase(postStudentsToClass.pending, (state) => {
                state.postStudentsStatus = 'loading';
            })
            .addCase(postStudentsToClass.fulfilled, (state) => {
                state.postStudentsStatus = 'success';
            })
            .addCase(postStudentsToClass.rejected, (state) => {
                state.postStudentsStatus = 'failed';
            });

        builder // DELETE STUDENTS
            .addCase(deleteStudentsFromClass.pending, (state) => {
                state.deleteStudentsStatus = 'loading';
            })
            .addCase(deleteStudentsFromClass.fulfilled, (state) => {
                state.deleteStudentsStatus = 'success';
            })
            .addCase(deleteStudentsFromClass.rejected, (state) => {
                state.deleteStudentsStatus = 'failed';
            });

        builder // POST EMPLOYEES
            .addCase(postEmployeesToClass.pending, (state) => {
                state.postEmployeesStatus = 'loading';
            })
            .addCase(postEmployeesToClass.fulfilled, (state) => {
                state.postEmployeesStatus = 'success';
            })
            .addCase(postEmployeesToClass.rejected, (state) => {
                state.postEmployeesStatus = 'failed';
            });

        builder // DELETE EMPLOYEES
            .addCase(deleteEmployeesFromClass.pending, (state) => {
                state.deleteEmployeesStatus = 'loading';
            })
            .addCase(deleteEmployeesFromClass.fulfilled, (state) => {
                state.deleteEmployeesStatus = 'success';
            })
            .addCase(deleteEmployeesFromClass.rejected, (state) => {
                state.deleteEmployeesStatus = 'failed';
            });

        builder // REVOKE LICENSE CLASS
            .addCase(changeLicenseClass.pending, (state) => {
                state.changeLicenseClassStatus = 'loading';
            })
            .addCase(changeLicenseClass.fulfilled, (state) => {
                state.changeLicenseClassStatus = 'success';
            })
            .addCase(changeLicenseClass.rejected, (state) => {
                state.changeLicenseClassStatus = 'failed';
            });
    },
});

export const {
    setFailedClass,
    setFailedClassMembers,
    setFailedClassLicense,
    resetClassStatus,
    resetSelectedClass
} = classManagementSlice.actions;

export default classManagementSlice.reducer;
