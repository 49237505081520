import { Box, Button, ButtonGroup, Card, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, TableHeader } from '../../../app/AppStyles';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { getPotentialEmployees, getPotentialStudents, getSpecificClass, postEmployeesToClass, postStudentsToClass, resetClassStatus, setFailedClassMembers } from './classManagementSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function ClassMemberAddCard({ handleClose, classId, mode }) {
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { potentialStudents, potentialStudentsStatus, potentialEmployees, potentialEmployeesStatus, postStudentsStatus, postEmployeesStatus, showClassMembersFailed } = useSelector((state) => state.management.class);

    const [filter, setFilter] = useState("");
    const [potentialMembers, setPotentialMembers] = useState([]);

    const [newMembers, setNewMembers] = useState([]);
    const [errorMembers, setErrorMembers] = useState(null);

    // Get potential members (students or employees)
    useEffect(() => {
        if (mode === "STUDENT")
            dispatch(getPotentialStudents(classId));
        else
            dispatch(getPotentialEmployees(classId));
    }, [dispatch, mode, classId]);

    // Set potential members to local list
    useEffect(() => {
        if (potentialStudentsStatus === "success" && mode === "STUDENT")
            setPotentialMembers(potentialStudents);

        if (potentialEmployeesStatus === "success" && mode === "EMPLOYEE")
            setPotentialMembers(potentialEmployees);
    }, [potentialStudentsStatus, potentialEmployeesStatus, mode]);

    // Post status callback
    useEffect(() => {
        if (mode === "STUDENT") {
            if (postStudentsStatus === "success") {
                dispatch(setFailedClassMembers(false));
                dispatch(resetClassStatus());
                dispatch(getSpecificClass(classId));
                handleClose();
            } else if (postStudentsStatus === "failed") {
                dispatch(resetClassStatus());
                dispatch(setFailedClassMembers(true));
            };
        };

        if (mode === "EMPLOYEE") {
            if (postEmployeesStatus === "success") {
                dispatch(setFailedClassMembers(false));
                dispatch(resetClassStatus());
                dispatch(getSpecificClass(classId));
                handleClose();
            } else if (postEmployeesStatus === "failed") {
                dispatch(resetClassStatus());
                dispatch(setFailedClassMembers(true));
            };
        };
    }, [dispatch, handleClose, postStudentsStatus, postEmployeesStatus, classId, mode]);

    // Add click function (check property character limits etc.)
    const addClick = () => {
        if (newMembers.length <= 0) return setErrorMembers(`${resourceFinal[appLanguage].messages.ERROR_LIST_EMPTY}`);
        setErrorMembers(null);

        var membersObject = {
            classId: classId,
            createdBy: `AP/${user.number}`
        };

        if (mode === "STUDENT") {
            membersObject.students = [];
            newMembers.forEach(item => membersObject.students.push(item));

            dispatch(postStudentsToClass(membersObject));
        };

        if (mode === "EMPLOYEE") {
            membersObject.employees = [];
            newMembers.forEach(item => membersObject.employees.push(item));

            dispatch(postEmployeesToClass(membersObject));
        };
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    var customFilterFunction = (item) => {
        return item.name.toLowerCase().includes(filter.toLowerCase()) || item.number.toLowerCase().includes(filter.toLowerCase());
    };

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && (filter ? numSelected < potentialMembers?.filter(customFilterFunction).length : numSelected < rowCount)}
                            checked={rowCount > 0 && (filter ? numSelected === potentialMembers?.filter(customFilterFunction).length : numSelected === rowCount)}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="center" sx={{ ...TableHeader, width: 100 }}>{mode === "STUDENT" ? resourceFinal[appLanguage].words.STUDENT : resourceFinal[appLanguage].words.ROLE_EMPLOYEE}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = potentialMembers?.filter(customFilterFunction).map((n) => n);
            setNewMembers(newSelected);
            return;
        };

        setNewMembers([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = newMembers.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(newMembers, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(newMembers.slice(1));
        } else if (selectedIndex === newMembers.length - 1) {
            newSelected = newSelected.concat(newMembers.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                newMembers.slice(0, selectedIndex),
                newMembers.slice(selectedIndex + 1),
            );
        };

        setNewMembers(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - potentialMembers?.filter(customFilterFunction).length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isSelected = (number) => newMembers.findIndex(x => x.number === number) !== -1;

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Stack spacing={1} sx={{ flex: '1 1 auto', maxWidth: 1, p: 0.5 }}>
                        <Typography variant="h6" sx={{ ...PageHeader }}>{mode === "STUDENT" ? resourceFinal[appLanguage].words.STUDENTS : resourceFinal[appLanguage].words.EMPLOYEES} {resourceFinal[appLanguage].words.ADD.toLowerCase()}</Typography>
                        <SearchField func={handleSearchChange} textRef={textRef} />
                        <TableContainer>
                            <Table>
                                <EnhancedTableHead numSelected={newMembers.length} onSelectAllClick={handleSelectAllClick} rowCount={potentialMembers?.length} />
                                <TableBody>
                                    {(rowsPerPage > 0 ? potentialMembers?.filter(customFilterFunction)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : potentialMembers?.filter(customFilterFunction))?.map((item, index) => {
                                        const isItemSelected = isSelected(item.number);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow hover onClick={(event) => handleClick(event, item)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item.number} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                                </TableCell>
                                                <TableCell align="center">{item.number}</TableCell>
                                                <TableCell align="left">{item.name}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {(rowsPerPage > 0 ? potentialMembers?.filter(customFilterFunction)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : potentialMembers?.filter(customFilterFunction)).length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                                    {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            sx={{ border: 0 }}
                                            rowsPerPageOptions={[5, 10, 20]}
                                            count={potentialMembers ? potentialMembers.length : 0}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        {errorMembers && <Typography variant="body1" color="error" sx={{ display: 'flex', justifyContent: 'center', alignText: 'center' }}>{errorMembers}</Typography>}
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'center', alignText: 'center' }}>{mode === "STUDENT" ? resourceFinal[appLanguage].words.STUDENTS : resourceFinal[appLanguage].words.EMPLOYEES} {resourceFinal[appLanguage].words.SELECTED.toLowerCase()}: {newMembers?.length}</Typography>
                        {showClassMembersFailed && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>}
                        <ButtonGroup sx={{ justifyContent: 'center' }}>
                            <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                            <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>
                        </ButtonGroup>
                    </Stack>
                </Scrollbars>
            </Card>
        </Box>
    );
}
