import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, ButtonGroup, Card, Stack, TextField, Typography } from '@mui/material';
import { getPeriods, postPeriod, putPeriod, resetPeriodStatus, setFailedPeriod } from './periodManagementSlice';
import { PageHeader } from '../../../app/AppStyles';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ConvertoToCSharpDateTime } from '../../../app/AppUtils';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function PeriodEditCard({ handleClose, addNew }) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedPeriod, showPeriodFailed, postPeriodStatus, putPeriodStatus } = useSelector((state) => state.management.period);
    const [changed, setChanged] = useState(false);

    // Put status callback
    useEffect(() => {
        if (putPeriodStatus === "success") {
            dispatch(setFailedPeriod(false));
            dispatch(resetPeriodStatus());
            dispatch(getPeriods());
            handleClose();
        } else if (putPeriodStatus === "failed") {
            dispatch(resetPeriodStatus());
            dispatch(setFailedPeriod(true));
        };
    }, [dispatch, handleClose, putPeriodStatus]);

    // Post status callback
    useEffect(() => {
        if (postPeriodStatus === "success") {
            dispatch(setFailedPeriod(false));
            dispatch(resetPeriodStatus());
            dispatch(getPeriods());
            handleClose();
        } else if (postPeriodStatus === "failed") {
            dispatch(resetPeriodStatus());
            dispatch(setFailedPeriod(true));
        };
    }, [dispatch, handleClose, postPeriodStatus]);

    // Character limits
    const nameLength = 64;

    // Property states
    const [newPeriod, setNewPeriod] = useState({});
    const [selectedStartTime, setSelectedStartTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);

    const [errorName, setErrorName] = useState(null);
    const [errorStartTime, setErrorStartTime] = useState(null);
    const [errorEndTime, setErrorEndTime] = useState(null);
    const [errorTime, setErrorTime] = useState(null);

    // Selected period state update 
    useEffect(() => {
        if (!addNew && selectedPeriod) {
            setNewPeriod(selectedPeriod);
            setSelectedStartTime(dayjs(selectedPeriod?.startTime));
            setSelectedEndTime(dayjs(selectedPeriod?.endTime));
        }
    }, [selectedPeriod, addNew]);

    // Keep track of changed state 
    useEffect(() => {
        if (newPeriod !== selectedPeriod) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [selectedPeriod, newPeriod]);

    // Check if subscribable before moment
    useEffect(() => {
        if (Date.parse(selectedStartTime) >= Date.parse(selectedEndTime)) {
            setErrorTime(true);
        } else {
            setErrorTime(false);
        };
    }, [selectedStartTime, selectedEndTime]);

    // Update click function (check property character limits etc.)
    const updateClick = () => {
        let tempObject = { ...newPeriod }; // Needed to circumvent weird read only property error

        if (!newPeriod.name) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        if (!selectedStartTime) return setErrorStartTime(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        if (!selectedEndTime) return setErrorEndTime(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        if (newPeriod.name && newPeriod.name?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        if (errorTime) return;

        setErrorName(null);
        setErrorStartTime(null);
        setErrorEndTime(null);

        tempObject.startTime = ConvertoToCSharpDateTime(selectedStartTime);
        tempObject.endTime = ConvertoToCSharpDateTime(selectedEndTime);
        tempObject.modifiedBy = `AP/${user.number}`;

        dispatch(putPeriod(tempObject));
    };

    // Add click function (check property character limits etc.)
    const addClick = () => {
        let tempObject = { ...newPeriod }; // Needed to circumvent weird read only property error

        if (!newPeriod.name) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        if (!selectedStartTime) return setErrorStartTime(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        if (!selectedEndTime) return setErrorEndTime(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        if (newPeriod.name && newPeriod.name?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_TOO_LONG} ${nameLength}`);
        if (errorTime) return;

        setErrorName(null);
        setErrorStartTime(null);
        setErrorEndTime(null);

        tempObject.startTime = ConvertoToCSharpDateTime(selectedStartTime);
        tempObject.endTime = ConvertoToCSharpDateTime(selectedEndTime);
        tempObject.createdBy = `AP/${user.number}`;

        dispatch(postPeriod(tempObject));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.PERIOD} {addNew ? resourceFinal[appLanguage].words.ADD.toLowerCase() : resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                    <TextField
                        fullWidth required
                        defaultValue={selectedPeriod?.name}
                        label={resourceFinal[appLanguage].words.NAME}
                        onChange={(event) => setNewPeriod({
                            ...newPeriod,
                            name: event.target.value
                        })}
                        error={errorName !== null}
                        helperText={errorName}
                        variant="outlined"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                        <DateTimePicker
                            slotProps={{ textField: { fullWidth: true } }}
                            label={`${resourceFinal[appLanguage].words.START}${resourceFinal[appLanguage].words.TIME.toLowerCase()}`}
                            value={selectedStartTime ?? null}
                            onChange={(value) => {
                                setChanged(true);
                                setSelectedStartTime(dayjs(value))
                            }} />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                        <DateTimePicker
                            slotProps={{ textField: { fullWidth: true } }}
                            label={`${resourceFinal[appLanguage].words.END}${resourceFinal[appLanguage].words.TIME.toLowerCase()}`}
                            value={selectedEndTime ?? null}
                            onChange={(value) => {
                                setChanged(true);
                                setSelectedEndTime(dayjs(value))
                            }} />
                    </LocalizationProvider>
                    {showPeriodFailed && <>
                        <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                    </>}
                    {errorTime && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.PERIOD_MANAGEMENT_EARLIER_START}</Typography>}
                    {errorStartTime && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.PERIOD_MANAGEMENT_EMPTY_START}</Typography>}
                    {errorEndTime && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.PERIOD_MANAGEMENT_EMPTY_END}</Typography>}
                    <ButtonGroup sx={{ justifyContent: 'center' }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                        {!addNew && <Button disabled={!changed} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                        {addNew && <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>}
                    </ButtonGroup>
                </Stack>
            </Card>
        </Box>
    );
}
