import { Box, Button, ButtonGroup, Checkbox, FormControlLabel, Grid, Radio, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ListItemBackground, PageHeader, TableHeader } from '../../app/AppStyles';
import { GetDate, GetTime } from '../../app/AppUtils';
import BackButton from '../subcomponents/BackButton';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { fetchMomentAttendance, postAttendances, resetAttendanceStatus, setAllCreatedBy, setAllModifiedBy, setAllPresents, setDispensation, setPresent, setRemark, setWorkedHours } from './attendanceSlice';

export default function AttendanceMomentPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { attendanceMoment, attendanceMomentStatus, postAttendanceStatus } = useSelector((state) => state.attendance);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { user } = useSelector((state) => state.user);

    const [allSelected, setAllSelected] = useState();
    const [anyEmpty, setAnyEmpty] = useState(false);
    const [emptyError, setEmptyError] = useState(false);
    const [sendMessage, setSendMessage] = useState(siomSettings?.emailSendAtAbsence);
    const [messageSubject, setMessageSubject] = useState('');
    const [messageBody, setMessageBody] = useState(siomSettings?.absenceText);

    useEffect(() => {
        if (attendanceMoment)
            setMessageSubject(`${siomSettings?.absenceSubject} | ${attendanceMoment?.activityName} | ${attendanceMoment?.performanceName ? attendanceMoment?.performanceName + " | " : ""}${GetDate(attendanceMoment?.startTime)}`)
    }, [attendanceMoment]);

    // Get moment attendances if empty
    useEffect(() => {
        if (attendanceMomentStatus === null) {
            dispatch(fetchMomentAttendance(id));
        };
    }, [dispatch, id, attendanceMomentStatus]);

    // Check if any required fields are empty
    useEffect(() => {
        attendanceMoment?.presents?.forEach((item) => {
            if (item.present === null) {
                setAnyEmpty(true);
            } else if (appSettings?.appVersion === 2 && item.workedHours === null && item.present) {
                setAnyEmpty(true);
            } else {
                setAnyEmpty(false);
            };
        });
    }, [attendanceMoment, siomSettings]);

    // post & put status callback
    useEffect(() => {
        if (postAttendanceStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.SAVE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetAttendanceStatus());
            dispatch(fetchMomentAttendance(id));
        } else if (postAttendanceStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetAttendanceStatus());
        };
    }, [postAttendanceStatus]);

    useEffect(() => {
        if (attendanceMoment) {
            if (attendanceMoment.presents[0].filledIn)
                dispatch(setAllModifiedBy(`AP/${user.number}`));
            else
                dispatch(setAllCreatedBy(`AP/${user.number}`));
        };
    }, [dispatch, user, attendanceMoment]);

    const handleAllGreenClick = () => { return dispatch(setAllPresents(true)); };
    const handleAllRedClick = () => { return dispatch(setAllPresents(false)); };
    const handleClose = () => navigate(-1);

    const handleSaveClick = () => {
        if (anyEmpty) return setEmptyError(true);
        setEmptyError(false);

        dispatch(postAttendances({
            momentId: attendanceMoment.presents[0].momentId,
            sendAbsentMessage: sendMessage,
            subject: messageSubject,
            body: messageBody,
            presents: attendanceMoment.presents,
            absenceNotification: `${resourceFinal[appLanguage].messages.NOTIFICATION_ABSENCE}`
        }));
    };

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.ATTENDANCE}`} content={
            <ContentLoader successCondition={attendanceMomentStatus === "success"} errorCondition={attendanceMomentStatus === "failed"} content={
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" sx={{ ...ListItemBackground, p: 1 }}>
                            <Typography variant="body1"><strong>{resourceFinal[appLanguage].words.ACTIVITY}:</strong> {attendanceMoment?.activityName}</Typography>
                            <Typography variant="body1"><strong>{resourceFinal[appLanguage].words.START}{resourceFinal[appLanguage].words.TIME.toLowerCase()}:</strong> {GetDate(attendanceMoment?.startTime)} ({GetTime(attendanceMoment?.startTime)})</Typography>
                            {attendanceMoment?.performanceName && <Typography variant="body1"><strong>{resourceFinal[appLanguage].words.PERFORMANCE}:</strong> {attendanceMoment?.performanceName}</Typography>}
                            <Typography variant="body1"><strong>{resourceFinal[appLanguage].words.PERFORMANCE}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}:</strong> {attendanceMoment?.performanceNumber}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', ...ListItemBackground }}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.STUDENT}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>
                                                <Stack>
                                                    {resourceFinal[appLanguage].words.ATTENDANCE}
                                                    <Box>
                                                        <Radio
                                                            checked={allSelected === "allFalse"}
                                                            onChange={(event) => { setAllSelected(event.target.value); handleAllRedClick() }}
                                                            value="allFalse"
                                                            color="error" sx={{ color: 'error.main' }}
                                                        />
                                                        <Radio
                                                            checked={allSelected === "allTrue"}
                                                            onChange={(event) => { setAllSelected(event.target.value); handleAllGreenClick() }}
                                                            value="allTrue"
                                                            color="success" sx={{ color: 'success.main' }}
                                                        />
                                                    </Box>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="center" padding="checkbox" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DISPENSATION}</TableCell>
                                            {appSettings?.appVersion === 2 && <TableCell align="center" sx={{ ...TableHeader, width: 100 }}>{resourceFinal[appLanguage].words.WORKED} {resourceFinal[appLanguage].words.HOURS.toLowerCase()}</TableCell>}
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.REMARK_ATTENDACNE}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attendanceMoment?.presents?.map(item => <TableFragment key={item.studentId} item={item} />)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1} sx={{ ...ListItemBackground, p: 1 }}>
                            <Typography variant="h6" sx={{ ...PageHeader, textAlign: 'left' }}>{resourceFinal[appLanguage].words.ABSENCE}{resourceFinal[appLanguage].words.MESSAGE.toLowerCase()}</Typography>
                            <FormControlLabel control={<Checkbox defaultChecked={siomSettings?.emailSendAtAbsence} onChange={(event) => setSendMessage(event.target.checked)} />} label={resourceFinal[appLanguage].messages.SEND_ABSENCE_MESSAGE} />
                            <TextField
                                fullWidth
                                value={messageSubject}
                                label={resourceFinal[appLanguage].words.SUBJECT}
                                onChange={(event) => setMessageSubject(event.target.value)}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth multiline
                                defaultValue={messageBody}
                                label={resourceFinal[appLanguage].words.BODY}
                                onChange={(event) => setMessageBody(event.target.value)}
                                variant="outlined"
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1 }}>
                            {(anyEmpty && emptyError) && <Typography variant="h6" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_ANY_EMPTY}</Typography>}
                            <ButtonGroup>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                <Button variant="outlined" color="success" onClick={handleSaveClick}>{resourceFinal[appLanguage].words.SAVE}</Button>
                            </ButtonGroup>
                        </Box>
                    </Grid>
                </Grid>
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } bottomBar />
    );
}

const TableFragment = ({ item }) => {
    const dispatch = useDispatch();
    const { appSettings } = useSelector((state) => state.settings.app);
    const hoursRef = useRef();

    useEffect(() => {
        if (!item.present && !item.dispensation) {
            dispatch(setWorkedHours({ id: item.studentId, value: 0 }))

            if (appSettings?.appVersion === 2)
                hoursRef.current.value = "";
        };
    }, [dispatch, item.present])

    return (
        <TableRow sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' } }}>
            <TableCell align="center">
                <Link to={`/zoeken/student/${item.studentId}`} style={{ color: 'black' }}>
                    {item.studentNumber}
                </Link>
            </TableCell>
            <TableCell align="left">{item.fullname}</TableCell>
            <TableCell align="center">
                <Radio
                    checked={item.present === false}
                    onChange={() => dispatch(setPresent({ id: item.studentId, value: false }))}
                    value={false}
                    color="error" sx={{ color: 'error.main' }}
                />
                <Radio
                    checked={item.present === true}
                    onChange={() => dispatch(setPresent({ id: item.studentId, value: true }))}
                    value={true}
                    color="success" sx={{ color: 'success.main' }}
                />
            </TableCell>
            <TableCell align="center"><Checkbox defaultChecked={item.dispensation} onChange={(event) => dispatch(setDispensation({ id: item.studentId, value: event.target.checked }))} colors="secondary" disabled={item.present !== false} /></TableCell>
            {appSettings?.appVersion === 2 && <TableCell align="center">
                <TextField
                    inputRef={hoursRef}
                    disabled={!item.present && !item.dispensation}
                    type="number"
                    inputProps={{ step: 0.5, min: 0 }}
                    defaultValue={item.workedHours}
                    onChange={(event) => dispatch(setWorkedHours({ id: item.studentId, value: event.target.value }))}
                />
            </TableCell>}
            <TableCell align="center">
                <TextField
                    multiline
                    defaultValue={item.remark}
                    onChange={(event) => dispatch(setRemark({ id: item.studentId, value: event.target.value }))}
                />
            </TableCell>
        </TableRow>
    );
};