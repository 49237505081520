import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemBackground, PageHeader, TableHeader } from '../../../app/AppStyles';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import { changeLicenseClass, getClassesForLicensing, resetClassStatus } from './classManagementSlice';

export default function LicenseClassesDisplay({ classes, mode }) {
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { changeLicenseClassStatus } = useSelector((state) => state.management.class);

    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selected, setSelected] = useState([]);

    var customFilterFunction = (item) => {
        return item.name.toLowerCase().includes(filter.toLowerCase());
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    useEffect(() => {
        if (changeLicenseClassStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.CLASS_MANAGEMENT_LICISING_SUCCES}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetClassStatus());
            dispatch(getClassesForLicensing());
        } else if (changeLicenseClassStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.CLASS_MANAGEMENT_LICISING_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetClassStatus());
        };
    }, [changeLicenseClassStatus]);

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    function EnhancedTableToolbar({ numSelected }) {
        const changeClick = () => {
            var changeObject = {
                classIds: selected,
                modifiedBy: `AP/${user.number}`
            };

            setSelected([]);
            dispatch(changeLicenseClass(changeObject));
        };

        return (
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', ...(numSelected > 0 && { bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) }) }}>
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {resourceFinal[appLanguage].words.SELECTED.toLowerCase()}
                    </Typography>
                ) : (
                    <>
                        <Typography
                            sx={{ ...PageHeader }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            {mode === "REMOVE" ? resourceFinal[appLanguage].words.LICENSED : resourceFinal[appLanguage].words.UNLICENSED}
                        </Typography>
                    </>
                )}
                {numSelected > 0 &&
                    <ConfirmationModal iconButton={true} icon={<PublishedWithChangesIcon color="secondary" />}
                        message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${numSelected} ${numSelected > 1 ? resourceFinal[appLanguage].words.CLASSES.toLowerCase() : resourceFinal[appLanguage].words.CLASS.toLowerCase()} ${resourceFinal[appLanguage].messages.CHANGE_CONFIRMATION}`}
                        confirmAction={() => changeClick(selected)}
                    />}
            </Toolbar>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = classes?.filter(customFilterFunction).map((n) => n.classId);
            setSelected(newSelected);
            return;
        };

        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        };

        setSelected(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - classes?.filter(customFilterFunction).length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => {
        return selected.findIndex(x => x === id) !== -1;
    };

    return (
        <Box sx={{ p: 0.5, ...ListItemBackground }}>
            <SearchField func={handleSearchChange} textRef={textRef} />
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
                <Table>
                    <EnhancedTableHead numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={classes?.filter(customFilterFunction).length} />
                    <TableBody>
                        {(rowsPerPage > 0 ? classes?.filter(customFilterFunction).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : classes?.filter(customFilterFunction))?.map((item, index) => {
                            const isItemSelected = isSelected(item.classId);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover onClick={(event) => handleClick(event, item.classId)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item?.classId} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                    <TableCell padding="checkbox">
                                        <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                    </TableCell>
                                    <TableCell align="left">{item?.name}</TableCell>
                                </TableRow>
                            );
                        })}
                        {(rowsPerPage > 0 ? classes?.filter(customFilterFunction).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : classes?.filter(customFilterFunction))?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                        {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                sx={{ border: 0 }}
                                rowsPerPageOptions={[5, 10, 25]}
                                count={classes ? classes?.filter(customFilterFunction).length : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
}

